import React, { Fragment } from "react";
import styles from "./copy.module.scss";
import { useAppContext } from "../context/Context";
import { useWindowSize } from "../../hooks/useWindowSize";

type Props = {
  copyright: Queries.Maybe<string>;
  className?: string;
};
export const Copy = ({ className, copyright }: Props) => {
  const { mode, setMode, indexConfig } = useAppContext();
  const { width } = useWindowSize();
  return (
    <div
      className={`${className} ${styles.wrapper} ${
        !indexConfig && styles.indexConfig
      } view`}
      onClick={() => indexConfig && setMode(!mode)}
    >
      {width > 870 && (
        <Fragment>
          <p>{copyright}</p>
          {<div className={indexConfig ? styles.dot : styles.empty} />}
        </Fragment>
      )}
    </div>
  );
};
