import React from "react";
import styles from "./social.module.scss";

type Props = {
  instagram: Queries.Maybe<string>;
  facebook: Queries.Maybe<string>;
  youtube: Queries.Maybe<string>;
};
export const Social = ({ instagram, youtube, facebook }: Props) => {
  return (
    <div className={styles.wrapper}>
      <a
        href={`${instagram}`}
        className="view"
        target="_blank"
        rel="noopener noreferrer"
      >
        instagram
      </a>
      <a
        href={`${facebook}`}
        className="view"
        target="_blank"
        rel="noopener noreferrer"
      >
        facebook
      </a>
      <a
        href={`${youtube}`}
        className="view"
        target="_blank"
        rel="noopener noreferrer"
      >
        youtube
      </a>
    </div>
  );
};
