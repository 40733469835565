import React from "react";
import { Link } from "gatsby";
import styles from "./navigation.module.scss";
import { useAppContext } from "../context/Context";
import { Logo } from "../logo/Logo";
import { useWindowSize } from "../../hooks/useWindowSize";

type Props = {
  email: Queries.Maybe<string>;
  logo: Queries.SanityImg;
};
export const Navigation = ({ email, logo }: Props) => {
  const { setFilteredActivity, setInitialTitle } = useAppContext();
  const { width } = useWindowSize();
  return (
    <nav className={styles.wrapper}>
      <ul>
        {width < 870 && <Logo logo={logo} />}
        <li>
          <Link
            to="/projects"
            onClick={() => {
              setFilteredActivity(null);
              setInitialTitle(false);
            }}
            className="view"
          >
            projects
          </Link>
        </li>
        <li>
          <Link to="/about" className="view">
            about
          </Link>
        </li>
        <li>
          <a className="view" href={`mailto:${email}`}>
            contact
          </a>
        </li>
      </ul>
    </nav>
  );
};
