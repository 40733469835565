import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Logo } from "../logo/Logo";
import { Navigation } from "../navigation/Navigation";
import { Banner } from "../banner/Banner";
import styles from "./layout.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import { Social } from "../social/Social";
import { Copy } from "../copy/Copy";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useAppContext } from "../context/Context";

type Props = {
  children: React.ReactNode;
};
export const Layout = ({ children }: Props) => {
  const [trigger, setTrigger] = useState(false);
  const { projectConfig, notFoundConfig, originalPath } = useAppContext();
  const { width } = useWindowSize();

  const scrollbarWidth = useMemo(() => {
    if (typeof window !== "undefined") {
      return window.innerWidth - document.body.clientWidth;
    }
    return 0;
  }, []);

  const scrollEffect = useCallback(() => {
    setTrigger(window.scrollY > 0 ? true : false);
  }, [setTrigger]);

  useEffect(() => {
    if (typeof window !== undefined) {
      window.addEventListener("scroll", scrollEffect);
    }
    if (typeof window !== "undefined" && scrollbarWidth !== null) {
      document.body.style.setProperty(
        "--viewportWidth",
        `calc(100vw - ${scrollbarWidth}px)`
      );
    }
    return () => {
      window.removeEventListener("scroll", scrollEffect);
    };
  }, [scrollbarWidth, scrollEffect]);

  const data = useStaticQuery(LAYOUT);
  const sanityLayout: Queries.SanityLayout = data.sanityLayout;
  if (!width) return;
  return (
    <Fragment>
      <header
        className={`${styles.header} ${
          projectConfig ? styles.mobileProject : styles.mobile
        } ${originalPath === "/" && styles.color}`}
      >
        {width > 870 && <Logo logo={sanityLayout.logo as Queries.SanityImg} />}
        <Navigation
          email={sanityLayout.email as Queries.Maybe<string>}
          logo={sanityLayout.logo as Queries.SanityImg}
        />
        {width > 870 && (
          <Social
            instagram={sanityLayout.instagram as Queries.Maybe<string>}
            facebook={sanityLayout.facebook as Queries.Maybe<string>}
            youtube={sanityLayout.youtube as Queries.Maybe<string>}
          />
        )}
      </header>

      <main className={styles.main}>{children}</main>
      {!notFoundConfig && (
        <footer
          className={`${styles.footer} ${
            projectConfig ? styles.mobileProject : styles.mobile
          } ${originalPath === "/" && styles.color}`}
        >
          <Banner />
          <Copy
            className={styles.copy}
            copyright={sanityLayout.copyright as Queries.Maybe<string>}
          />
        </footer>
      )}
    </Fragment>
  );
};

export const LAYOUT = graphql`
  query Layout {
    sanityLayout {
      instagram
      facebook
      youtube
      email
      copyright
      logo {
        ...image
      }
    }
  }
`;
