module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"PARKER MORRAIN","short_name":"Parker Morrain","description":"Director Editor Photo","lang":"fr","display":"standalone","icon":"static/favicons/favicon-32x32.png","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#000","theme_color":"#fff","localize":[{"start_url":"/en/","lang":"en","name":"PARKER MORRAIN","short_name":"Parker Morrain","description":"Director Editor Photo"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f4703d05ad89733c4972d1b247dc2e59"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
