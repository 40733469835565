import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { TitleType } from "../banner/Banner";

export type SanityLanguage = keyof Queries.SanityLocaleText &
  keyof Queries.SanityLocaleString &
  string;

type ContextProps = {
  mode: boolean;
  setMode: (v: boolean) => void;
  initial: boolean;
  setInitial: (v: boolean) => void;
  initialTitle: boolean;
  setInitialTitle: (v: boolean) => void;
  isFullScreen: boolean;
  setIsFullScreen: (v: boolean) => void;
  lang: SanityLanguage;
  setLang: (lang: SanityLanguage) => void;
  defaultLang: SanityLanguage;
  originalPath: string;
  filteredActivity: TitleType;
  setFilteredActivity: (c: TitleType) => void;
  filteredActivity2: TitleType;
  setFilteredActivity2: (c: TitleType) => void;
  indexConfig: boolean;
  projectConfig: boolean;
  notFoundConfig: boolean;
};

const Context = createContext<ContextProps>({
  mode: false,
  setMode: () => {},
  initial: false,
  setInitial: () => {},
  initialTitle: false,
  setInitialTitle: () => {},
  defaultLang: "fr",
  lang: "fr",
  setLang: () => {},
  originalPath: "/",
  isFullScreen: false,
  setIsFullScreen: () => {},
  filteredActivity: null,
  setFilteredActivity: () => {},
  filteredActivity2: null,
  setFilteredActivity2: () => {},
  indexConfig: false,
  projectConfig: false,
  notFoundConfig: false,
});

type ContextWrapperProps = {
  children: React.ReactNode;
  pageContext: any;
};

const defaultLang = "fr";

export const ContextProvider = ({
  children,
  pageContext,
}: ContextWrapperProps) => {
  const { width } = useWindowSize();
  const indexConfig = width > 870 && pageContext.originalPath !== "/";
  const projectConfig =
    (width < 870 && pageContext.originalPath === "/projects/") ||
    pageContext.originalPath === "/about/";
  const notFoundConfig = width > 870 && pageContext.originalPath === "/404/";
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [mode, setMode] = useState(false);
  const [initialTitle, setInitialTitle] = useState(false);
  const [filteredActivity, setFilteredActivity] = useState<TitleType>();
  const [filteredActivity2, setFilteredActivity2] = useState<TitleType>();
  const [initial, setInitial] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined" && window.document) {
      document.body.setAttribute("datatype", mode ? "dark" : "light");
    }
  }, [mode]);

  const [lang, setLang] = useState<SanityLanguage>(pageContext.lang);

  const value = useMemo(
    () => ({
      ...pageContext,
      lang,
      setLang,
      mode,
      setMode,
      initial,
      setInitial,
      isFullScreen,
      setIsFullScreen,
      filteredActivity,
      setFilteredActivity,
      filteredActivity2,
      setFilteredActivity2,
      indexConfig,
      projectConfig,
      notFoundConfig,
      initialTitle,
      setInitialTitle,
      defaultLang,
    }),
    [
      pageContext,
      lang,
      setLang,
      mode,
      setMode,
      initial,
      setInitial,
      isFullScreen,
      setIsFullScreen,
      filteredActivity,
      setFilteredActivity,
      filteredActivity2,
      setFilteredActivity2,
      indexConfig,
      projectConfig,
      notFoundConfig,
      initialTitle,
      setInitialTitle,
      defaultLang,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAppContext = () => {
  const context = useContext(Context);

  if (context == null) {
    throw new Error("Components must be wrapped in <GlobalContext />");
  }

  return context;
};
