import React, { useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./transition.css";

type Props = {
  children: React.ReactNode;
  path: string;
};
export const Transition = ({ children, path }: Props) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [trigger, setTrigger] = useState<string>("/");
  return (
    <CSSTransition
      in={path === trigger ? false : true}
      nodeRef={nodeRef}
      timeout={1000}
      classNames="transition"
      onEnter={() => setTrigger(path)}
      onExit={() => {}}
    >
      <div ref={nodeRef} className="screen-transition">
        {children}
      </div>
    </CSSTransition>
  );
};
