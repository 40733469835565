import React, { Fragment } from "react";
import { GatsbyBrowser } from "gatsby";
import { Layout } from "./src/components/layout/Layout";
import "./styles/global.scss";
import { ContextProvider } from "./src/components/context/Context";
import { Cursor } from "./src/components/cursor/Cursor";
import { Transition } from "./src/components/transition/Transition";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  return (
    <Fragment>
      <ContextProvider pageContext={props.pageContext}>
        <Cursor path={props.path}>
          <Transition path={props.path}>
            <Layout>{element}</Layout>
          </Transition>
        </Cursor>
      </ContextProvider>
    </Fragment>
  );
};
