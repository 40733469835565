import React, { useRef } from "react";
import { useAppContext } from "../context/Context";
import { navigate } from "gatsby";
import styles from "./banner.module.scss";

export type TitleType = "director" | "editor" | "photo" | null;

const titles: TitleType[] = ["director", "editor", "photo"];

export const Banner = () => {
  const titleRef = useRef<(HTMLHeadingElement | null)[]>([]);

  const {
    setFilteredActivity,
    setFilteredActivity2,
    setInitialTitle,
    initial,
  } = useAppContext();

  return (
    <div className={styles.wrapper}>
      {titles.map((title, index) => (
        <div
          key={`title=${index}`}
          className={`${styles.title} view`}
          onClick={() => {
            setFilteredActivity(title);
            !initial && setFilteredActivity2(title);
            setInitialTitle(true);
            navigate("/projects");
          }}
        >
          <h1 ref={(ref) => (titleRef.current[index] = ref)}>{title}</h1>
        </div>
      ))}
    </div>
  );
};
