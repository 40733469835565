import React from "react";
import styles from "./logo.module.scss";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

type Props = {
  logo: Queries.SanityImg;
};

export const Logo = ({ logo }: Props) => {
  return (
    <Link to="/" className={`${styles.logo} view`}>
      <GatsbyImage
        alt={logo?.alt ?? ""}
        image={logo?.image?.asset?.gatsbyImageData as IGatsbyImageData}
      />
    </Link>
  );
};
