import React, { Fragment, useEffect, useState } from "react";
import { useRef } from "react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import styles from "./cursor.module.scss";
import { useAppContext } from "../context/Context";

type Props = { children: React.ReactNode; path: string };

export const Cursor = ({ children, path }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [getLinks, setGetLinks] = useState<NodeListOf<Element>>();
  const { isFullScreen } = useAppContext();
  useEffect(() => {
    const timer = setTimeout(() => {
      const links = document.querySelectorAll(".view");
      setGetLinks(links);
    }, 0);
    return () => clearTimeout(timer);
  }, [path, isFullScreen]);

  useGSAP(
    () => {
      gsap.set(ref.current, { xPercent: -50, yPercent: -50 });
      window.addEventListener("mousemove", (e) => {
        const x = e.clientX;
        const y = e.clientY;
        gsap.to(ref.current, {
          x: x,
          y: y,
          ease: "power3.out",
          duration: 0.3,
        });
      });

      getLinks?.forEach((link) => {
        link.addEventListener("mouseenter", (event: { target: any }) => {
          const link = event.target;
          const target = link?.classList.contains("view");

          if (target) {
            gsap.to(ref.current, {
              scale: 2,
              ease: "power3.out",
              duration: 0.3,
            });
          }
        });
      });

      getLinks?.forEach((link) => {
        link.addEventListener("mouseleave", () => {
          gsap.to(ref.current, {
            scale: 1,
            ease: "power3.out",
            duration: 0.3,
          });
        });
      });
    },
    { dependencies: [getLinks] }
  );

  return (
    <Fragment>
      <div ref={ref} className={styles.wrapper} />
      {children}
    </Fragment>
  );
};
